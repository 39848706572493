<template>
  <div class="item-container m-3">
    <b-link
      :class="{ 'badge-border' : isShowBadge}"
      class="dashpanel-link"
      :aria-label="title + ' Link'"
      @click="logNavigation(linkTo, 'dashpanel', 'navigation', interaction)"
    >
      <b-badge
        v-if="isShowBadge"
        variant="danger"
        class="dashpanel-badge d-flex align-items-center justify-content-center"
      >
        {{ simplifiedNumber(badge) }}
      </b-badge>
      <div class="dashpanel-icon d-flex align-items-center justify-content-center mb-4">
        <b-img :src="'/images/dashpanel/' + typeIcons[type]"  />
      </div>
      <div class="mb-5 label">
        {{title}}
      </div>
    </b-link>
  </div>
</template>

<script>
import { simplifiedNumber } from '@/common/modules/strings'
import { logNavigationMixin } from '@/mixins/logNavigationMixin'

export default {
  name: 'DashpanelItem',
  mixins: [logNavigationMixin],
  props: {
    badge: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      typeLinks: {
        'unreadDocuments': 'documents',
        'unpaidInvoices': 'invoices',
        'servicesRequiringAttention': 'services-requiring-attention',
        'pendingFilings': 'pending-filings',
        'incompleteStagelineStages': 'stageline-v2',
        'hireUs': 'hire-us',
        'faq': 'faq',
        'contactSupport': 'contact-support',
      },
      typeIcons: {
        'unreadDocuments': 'icon-unread-documents.svg',
        'unpaidInvoices': 'icon-unpaid-invoices.svg',
        'servicesRequiringAttention': 'icon-services-requiring-attention.svg',
        'pendingFilings': 'icon-filing-orders.svg',
        'incompleteStagelineStages': 'icon-manage-your-business.svg',
        'hireUs': 'icon-add-services.svg',
        'faq': 'icon-faq.svg',
        'contactSupport': 'icon-contact-support.svg',
      },
    }
  },
  computed: {
    isShowBadge() {
      return this.badge > 0
    },
    interaction() {
      return { action: 'visit', to: this.linkTo, title: this.title }
    },
    linkTo() {
      return this.typeLinks[this.type]
    },
  },
  methods: {
    simplifiedNumber: simplifiedNumber,
  },
}
</script>

<style lang="scss" scoped>
.item-container {
  .dashpanel-link {
    width: 18.75rem;
    height: 18.75rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border-radius: 4px;

    background-color: #F1F1F2;

    &:hover {
      color: #0057ca !important;
      text-decoration: none;
      background: #f8f8f8;
    }

    .label {
      line-height: 1.3rem;
      color: #030404;
    }

    .dashpanel-badge {
      position: relative;
      top: -2.25rem !important;
      right: -9.25rem !important;
      font-size: 95%;
      min-width: 1.75rem;
      height: 1.75rem;
      background-color: #FF1800 !important;
      border-radius: 6.25rem;
    }

    .dashpanel-icon {
      background-color: white;
      width: 150px;
      height: 150px;
      border-radius: 50%;
    }
  }
  .badge-border {
    border: 1px solid #F45151 !important;
  }
}
</style>
